.whyZenadminSection {
  background-color: var(--p-10);
  padding: 120px 0;
}

.headingContainer {
  max-width: var(--max-screen-width);
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 64px;
}

.title {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-44);
  letter-spacing: -0.4px;
}

.subTitle {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-28);
  letter-spacing: 0.2px;
}

.cardContainer {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: stretch;
  position: relative;
  padding: 0 32px;
}

.card {
  color: #191653;
  padding: 40px 32px;
  border: 1px solid var(--white);
  border-radius: 16px;
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
  flex: 1;
  min-height: 260px;
}

.cardIconBox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  background-color: var(--white);
  border-radius: 16px;
}

.cardHeading {
  font-size: var(--font-size-24);
  line-height: var(--line-height-24);
  font-weight: var(--font-weight-600);
  letter-spacing: -1%;
  margin-top: 32px;
}

.cardText {
  font-size: var(--font-size-18);
  line-height: var(--line-height-24);
  font-weight: var(--font-weight-400);
  margin-top: 16px;
}

.activeCard {
  opacity: 1;
  background-color: var(--white);
  transition: transform 0.5s ease, background-color 0.5s ease;
  transform: scaleY(1.03);
}
.activeCard .cardIconBox {
  transform: scaleY(0.97);
}
.activeCard .cardHeading {
  font-size: var(--font-size-28);
}

.activeCard .cardIconBox {
  background-color: var(--p-50);
}

.cardNameContainer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.cardName {
  color: var(--text-secondary);
  font-size: var(--font-size-18);
  line-height: var(--line-height-23);
  font-weight: var(--font-weight-500);
  padding-right: 16px;
  border-right: 1px solid var(--white);
  margin-right: 16px;
  flex-shrink: 0;
}

.cardName:last-child {
  border: none;
}

.activeCardName {
  color: var(--p-50);
}

@media screen and (max-width: 768px) {
  .whyZenadminSection {
    padding: 80px 0 70px 0;
  }
  .headingContainer {
    gap: 12px;
    margin-bottom: 48px;
  }
  .cardNameContainer {
    justify-content: unset;
    margin-left: 24px;
    margin-top: 30px;
    padding: 10px 0;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
  }
  .cardNameContainer::-webkit-scrollbar {
    display: none;
  }
  
  .activeCard {
    transform: none;
  }
  .activeCard .cardIconBox {
    transform: none;
  }
}
