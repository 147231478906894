.container {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  padding: 120px 0 40px 0;
  display: flex;
  gap: 12%;
}
.title {
  max-width: 300px;
}
.listContainer {
  width: 100%;
}
.faqCard {
  padding: 24px;
  border-bottom: 1px solid var(--stroke-buttons-input);
}
.questionRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-32);
  letter-spacing: -0.2px;
}
.questionRow h5 {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-32);
  letter-spacing: -0.2px;
}
.ansText {
  color: var(--text-secondary);
  padding-top: 16px;
}

@media screen  and (max-width: 800px){
  .container {
    flex-direction: column;
    padding: 80px 0;
  }
  .title {
    padding: 0 24px;
    margin-bottom: 32px;
    max-width: 100%;
  }
  .questionRow h5{
    font-size: var(--font-size-h6);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-24);
    letter-spacing: -0.2px;
  }
}
