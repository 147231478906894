.mainFooterContainer {
  overflow-x: hidden;
}
.footer {
  background-color: var(--p-90);
  color: var(--white);
  padding-top: 180px;
  margin-top: 200px;
  position: relative;
}
.footerImageCard {
  position: absolute;
  padding: 48px 0;
  min-height: 200px;
  height: auto;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  background-color: var(--p-50);
  max-width: 1284px;
  width: 80%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  z-index: 4;
}
.imageCardTop60 {
  top: -0%;
}
.imageCardTop40 {
  top: -0%;
}
.btnIconBox {
  background-color: var(--p-50);
  border-radius: 12px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.textContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.footerContainer {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
}

.footerBottomDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  border-top: 1px solid #0f1f57;
}
.footerCardTextContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 60%;
}
.footerCardText span {
  display: block;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.gap12 {
  gap: 12px;
}

.bookDemoBtn {
  padding: 16px 19px;
  border-radius: 16px;
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-24);
  letter-spacing: -0.2px;
}
.policyText {
  gap: 40px;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-18);
}

.footerBottomLogo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.lastFooterImage {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1284px) {
  .footerContainer {
    max-width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .footerSection {
    margin-top: 48px;
  }
  .footerBottomDetails {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    gap: 40px;
    padding-top: 40px;
    padding-bottom: 80px;
  }
  .textContainer {
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
  }
  .footerImageCard {
    padding: 24px;
  }
  .footerCardTextContainer {
    width: 100%;
  }
  .imageCardTop40 {
    top: -0%;
  }
  .imageCardTop60 {
    top: -0%;
  }
}

@media (min-width: 550px) and (max-width: 650px) {
  .footer {
    padding-top: 32%;
    margin-top: 36%;
  }
}
@media (min-width: 400px) and (max-width: 550px) {
  .footer {
    padding-top: 48%;
    margin-top: 52%;
  }
}
@media screen and (max-width: 500px) {
  .footerTitle {
    padding: 0 24px;
  }
  .footerImageCard {
    height: auto;
    width: calc(100% - 48px);
    padding: 0;
  }
  .textContainer {
    padding: 40px 24px;
    gap: 32px;
  }
  .footerSection {
    border-top: 1px solid var(--p-80);
    padding-top: 48px;
  }
  .footerCardText span {
    display: inline;
  }
}

@media screen and (max-width: 400px) {
  .footer {
    padding-top: 72%;
    margin-top: 72%;
  }
}
